import { template as template_380ca496e4854c14a8f65fdecbcb0626 } from "@ember/template-compiler";
const FKLabel = template_380ca496e4854c14a8f65fdecbcb0626(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
