import { template as template_4ae97885150144d882cd8950431ddc44 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4ae97885150144d882cd8950431ddc44(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
